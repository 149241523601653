/* You can add global styles to this file, and also import other style files */
@use '@material/animation' as animation-variables;
$standard-curve-timing-function: animation-variables.$standard-curve-timing-function !default;
@use '@angular/material' as mat;
@use './responsive';
@use './colors';
@use './mpo-flex.scss';

@import "@angular/material/prebuilt-themes/indigo-pink.css";


$corp-orange: rgb(227, 94, 36);
$disabled-checkbox: #b0b0b0;

$highdensity: 'only screen and (-webkit-min-device-pixel-ratio: 1.5)', 'only screen and (min--moz-device-pixel-ratio: 1.5)',
    'only screen and (-o-min-device-pixel-ratio: 3/2)', 'only screen and (min-device-pixel-ratio: 1.5)';
$mobile: 'only screen and (min-device-width: 320px) and (max-device-width: 823px)';
$tablet: 'only screen and (min-width: 530px) and (max-width: 949px)';
$tablet-landscape: 'only screen and (min-width: 950px) and (max-width: 1024px)';
$small-screen: 'only screen and (max-width: 1228px)';
$desktop: 'only screen and (min-width: 1025px) and (max-width: 1128px)';
$desktop-xl: 'only screen and (min-width: 1129px)';

$word-wrap-all: #{'break-all'};

body::after {
    content: url(/assets/menu_gauche/N.png) url(/assets/menu_gauche/menu_principal/individus.png) url(/assets/menu_gauche/menu_principal/individus_hover.png) url(/assets/menu_gauche/menu_principal/individus_selected.png) url(/assets/menu_gauche/menu_principal/analyse.png) url(/assets/menu_gauche/menu_principal/analyse_hover.png) url(/assets/menu_gauche/menu_principal/analyse_selected.png) url(/assets/menu_gauche/menu_principal/postes.png) url(/assets/menu_gauche/menu_principal/postes_hover.png) url(/assets/menu_gauche/menu_principal/postes_selected.png) url(/assets/menu_gauche/menu_principal/archives.png) url(/assets/menu_gauche/menu_principal/archives_hover.png) url(/assets/menu_gauche/menu_principal/archives_selected.png) url(/assets/menu_gauche/menu_principal/admin.png) url(/assets/menu_gauche/menu_principal/admin_hover.png) url(/assets/menu_gauche/menu_principal/admin_selected.png) url(/assets/menu_gauche/menu_principal/compte.png) url(/assets/menu_gauche/menu_principal/compte_hover.png) url(/assets/menu_gauche/menu_principal/compte_orange.png) url(/assets/menu_gauche/menu_principal/tools.png) url(/assets/menu_gauche/menu_principal/tools_hover.png) url(/assets/menu_gauche/menu_principal/tools_selected.png);
    visibility: hidden;
    position: absolute;
    top: 0;
}

html {
    /* mobile viewport bug fix */
    height: -webkit-fill-available;
}

html,
body {
    width: 100%;
    height: 100vh;
    overflow: hidden !important;
    margin: 0;
    padding: 0;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
}

body {
    background: no-repeat fixed center center;
    color: #6a6a6a;
    font-family: 'MontserratLight', sans-serif;
    font-size: 12px;
    line-height: 1.5em;
}
.mat-mdc-row,
.mdc-data-table__content {
    color: #6a6a6a!important;
    font-family: 'MontserratLight', sans-serif!important;
    font-size: 12px!important;
    line-height: 1.5em!important;
}

input[type='checkbox'] {
    accent-color: rgb(227, 94, 36);
}

input[type='radio'] {
    accent-color: rgb(227, 94, 36);
}

#scrollBody {
    overflow-y: scroll;
    height: 100vh;
    padding-bottom: 75px;
    position: relative;
}

.modal-body {
    background-color: #eeeeee;
    font-family: "MontserratLight", sans-serif;
    .mdc-label {
        font-size: 12px;
    }
}

.red {
    color: #dc3545;
}

.redBlock {
    background: #dc3545;
    display: inline-block;
    height: 12px;
    width: 30px;
    position: relative;
    top: 3px;
}

.profileWarning {
    padding-top: 10px;
    color: #dc3545;
}

.mat-checkbox-input {
    left: 1px !important;
}

.mat-checkbox:not(.mat-checkbox-checked) .mat-checkbox-background {
    background: #ffffff;
    margin: 2px;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $corp-orange;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: $disabled-checkbox;
    margin: 0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
    border-color: $disabled-checkbox;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $corp-orange;
}

.cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    // Avoid browsers rendering the focus ring in some cases.
    outline: 0;

    // Avoid some cases where the browser will still render the native controls.
    -webkit-appearance: none;
    -moz-appearance: none;
}

.autoGenWrap label.mat-checkbox-layout {
    margin-top: 0;
}

.mat-tab-group {
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-tab-nav-bar,
.mat-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-ripple {
    overflow: hidden;
    position: relative;
}

.mat-tab-label,
.mat-tab-link {
    color: rgb(134, 134, 134);
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
    opacity: 1 !important;
}

.infoTableWrap .mat-tab-label:focus,
.infoTableWrap .mat-tab-label-active {
    color: rgb(85, 85, 85) !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $corp-orange;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.38);
}

.infoWrap {
    width: 100%;
    overflow: hidden;

    @media only screen and (max-width: 1234px) {
        max-width: 400px;
        width: 400px;
    }
}

.infoWrap .mat-tab-header-pagination-before,
.infoWrap .mat-tab-header-pagination-after {
    display: none !important;
}

.infoWrap .mat-tab-list {
    transform: none !important;
}

/* It's important to keep these instructions to avoid conflicts with Material inline styles */
.infoWrap .mat-ink-bar,
.infoWrap .mat-tab-label {
    max-width: 160px !important;
}

.infoWrap .mat-tab-label {
    justify-content: flex-start !important;
}

.infoWrap .infoTable {
    margin-top: 10px;
    font-family: 'MontserratLight', sans-serif !important;
    width: 295px;
}

.infoWrap .infoTable strong,
.informationPanel .infoTable strong {
    font-weight: 700;
}

.infoTableWrap {
    width: 100%;
}

.infoTableWrap td {
    vertical-align: top;
}

.infoTableWrap td.switchButton {
    padding: 7px 5px 0 2px;
    // Fix pour le bouton informations
    width: 50px;
}

.switchButton button {
    font-family: 'MontserratLight', sans-serif;
    font-size: 15pt;
    cursor: pointer;
    border-radius: 0;
}

.infoTableWrap td.modifyButton {
    padding: 18px 0 0 0;
}

.addItem-btn {
    background: transparent;
}

.width95 {
    width: 95%;
}

.dropDownPlHolder {
    display: block;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkedItem {
    font-family: MontserratLight sans-serif;
    font-size: 150%;
}

/* Multiselect */
ng-multiselect-dropdown .multiselect-dropdown {
    text-align: left !important;
    color: #333333 !important;
    background-color: #ffffff !important;
    border-color: #cccccc !important;
    font-size: 14px !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-list {
    padding-bottom: 6px;
}

ng-multiselect-dropdown .multiselect-dropdown li {
    padding: 4px 10px !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn:focus {
    outline: none !important;
    background-color: #cccccc !important;
}

ng-multiselect-dropdown .multiselect-dropdown li:focus {
    outline: none !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn {
    padding-right: 0 !important;
    height: 35px !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn span span:not(.dropdown-multiselect__caret),
ng-multiselect-dropdown .dropdown-btn .selected-item {
    display: none !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    color: #333 !important;
    border-width: 5px 5px 0 !important;
    border-color: #333 transparent !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn .dropdown-down {
    border-top: 4px solid #333333 !important;
    border-left: 4px solid transparent !important;
    border-right: 4px solid transparent !important;
    margin-bottom: 3px !important;
}

ng-multiselect-dropdown .multiselect-dropdown .dropdown-btn .dropdown-up {
    border-bottom: 4px solid #333333 !important;
    border-left: 4px solid transparent !important;
    border-right: 4px solid transparent !important;
    margin-bottom: 3px !important;
}

ng-multiselect-dropdown .multiselect-item-checkbox input[type='checkbox']+div:before {
    border: 1px solid #333333 !important;
    top: 60% !important;
    border-radius: 3px !important;
    left: 1px !important;
}

ng-multiselect-dropdown .multiselect-item-checkbox input[type='checkbox']+div:after {
    top: 55% !important;
}

ng-multiselect-dropdown .multiselect-item-checkbox input[type='checkbox']:hover+div:before {
    border-color: $corp-orange !important;
}

ng-multiselect-dropdown .multiselect-item-checkbox input[type='checkbox']:checked+div:before {
    background: $corp-orange !important;
    border: 1px solid $corp-orange !important;
    color: $corp-orange !important;
}

.addItem-btn:focus {
    outline: none;
    border: none;
}

.addItem-title {
    position: relative;
    bottom: 8px;
}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v48/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

mat-carousel {
    font-size: 14px;
}

mat-carousel table {
    height: 100%;
    width: 80%;
    margin: 0 auto;
}

mat-carousel table.fullWidth {
    width: 100%;
}

mat-carousel tr:first-child {
    height: 90%;
}

mat-carousel tr:first-child td {
    vertical-align: middle;
}

mat-carousel tr:last-child td {
    vertical-align: bottom;
}

mat-carousel h1 {
    font-weight: bold;
}

mat-carousel h2 {
    font-family: 'MontserratLight', sans-serif;
    font-size: 24px;
    font-weight: bold;
}

mat-carousel .mat-mini-fab.mat-accent {
    background-color: #595959;
    border: 1px #595959 solid;
}

mat-carousel .mat-mini-fab.mat-accent[disabled],
mat-carousel .mat-mini-fab[disabled][disabled] {
    background-color: #ffffff;
    border: 1px #595959 solid;
}

mat-carousel ul {
    list-style-type: disc;
    padding-inline-start: 20px;
}

mat-carousel ul li {
    margin-bottom: 25px;
    font-size: 17px;
}

mat-carousel ul li img {
    margin: 0 auto;
}

.carousel-indicators {
    display: block !important;
    transform: translateX(0) !important;
    -webkit-transform: translateX(0) !important;
    left: 55% !important;
    width: 50% !important;
}

.carousel-indicators>button {
    width: 20px !important;
    height: 20px !important;
}

/* Drag&Drop - animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    color: rgba(255, 255, 255, 0.7) !important;
    padding-top: 5px !important;
    padding-left: 15px !important;
    opacity: 1;
    background-color: #bababa;
}

$mobile: 480px;
@media only screen and (min-device-width: 320px) and (max-device-width: 823px) {
    // @include mat.core();
    // Define the palettes for your theme using the Material Design palettes available in palette.scss
    // (imported above). For each palette, you can optionally specify a default, lighter, and darker
    // hue. Available color palettes: https://material.io/design/color/
    // $candy-app-primary: mat.define-palette(mat.$orange-palette);
    // $candy-app-accent: mat.define-palette(mat.$deep-orange-palette, A200, A100, A400);

    // The warn palette is optional (defaults to red).
    // $candy-app-warn: mat.define-palette(mat.$red-palette);

    // Create the theme object (a Sass map containing all of the palettes).
    // $candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

    // Include theme styles for core and each component used in your app.
    // Alternatively, you can import and @include the theme mixins for each component
    // that you are using.
    // @include mat.all-component-themes($candy-app-theme);

    .mat-mdc-row {
        color: rgba(0, 0, 0, 0.87)!important;
        font-family: Roboto, "Helvetica Neue", sans-serif!important;
        font-size: 14px!important;
        height: 48px!important;
    }

    body {
        /* overflow-y: auto !important;*/
        padding-bottom: env(safe-area-inset-bottom);
        min-height: -webkit-fill-available;
    }

    .mobileHeader {
        top: 5px;
    }

    .reloadLink {
        margin-left: 5px;
        display: inline-block;
        position: relative;
        top: -2px;
    }

    .scrollableList {
        max-height: calc(100vh - 103.5px);
        overflow-x: hidden;
    }

    .sectionTitle {
        font-size: 2.3rem;
        padding-left: 10px;
    }

    .formWrapper {
        padding: 2.5%;
    }

    .mat-grid-list-wrapper {
        background-image: url('/assets/login/background.png');
        height: 100vh;
        padding: 10px;
        color: white;
    }

    .mat-grid-list-wrapper a {
        color: white;
    }

    .mat-grid-tile #logoContainer {
        position: absolute;
        left: 0;
    }

    .mat-grid-tile #logoContainer img {
        max-width: 36vw;
    }

    .mat-grid-tile .languageSwitch,
    .mat-grid-tile .versionNumber {
        position: absolute;
        right: 0;
    }

    .mat-grid-tile .languageSwitch {
        top: 0;
    }

    .mat-grid-tile .versionNumber {
        bottom: 0;
        margin-bottom: 5px;
    }

    .mat-grid-tile hr {
        width: 100%;
        position: absolute;
        top: 0;
        border-color: #ffffff;
    }

    .mat-grid-tile h1 {
        font-family: 'MontserratLight', sans-serif;
        font-size: 2rem;
        padding: 0;
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    .mat-grid-tile .topFullWidth {
        width: 100%;
        position: absolute;
        top: 0;
    }

    .mat-button.mat-action,
    .mat-icon-button.mat-action,
    .mat-stroked-button.mat-action {
        background-color: #4169e1;
        color: #ffffff;
    }

    .mat-button.mat-cancel,
    .mat-icon-button.mat-cancel,
    .mat-stroked-button.mat-cancel {
        background-color: #a5a5a5;
        color: #ffffff;
    }

    .mat-button.mat-black,
    .mat-icon-button.mat-black,
    .mat-stroked-button.mat-black,
    .mat-raised-button.mat-black {
        background-color: #000000;
        color: #ffffff;
    }

    .mat-form-field-flex {
        border-bottom: 1px white solid;
    }

    .mat-form-field-label-wrapper {
        top: -0.74375em;
    }

    .mat-toolbar {
        font-size: 1.2rem;
        border-radius: 5px;
    }

    input.mat-input-element,
    .mat-select-value {
        padding-top: 0.4em !important;
        color: #555555 !important;
        font-size: 14px;
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
        color: #ffffff;
    }

    .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button {
        vertical-align: super !important;
    }

    .mat-form-field-label span {
        font-size: 1.5rem;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        background-color: transparent !important;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
    }

    .mobile-login-form {
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus {
            background-color: transparent !important;
            -webkit-text-fill-color: #555555;
            transition: background-color 5000s ease-in-out 0s;
        }
    }

    .formWrapper input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        -webkit-text-fill-color: #000000;
    }

    .mat-grid-list-wrapper .formTable a {
        color: white;
    }

    #table_loginForm.formTable {
        width: 100%;
    }

    #table_loginForm .mat-form-field.mat-focused .mat-form-field-label {
        color: #ffffff !important;
    }

    #table_loginForm .mat-form-field.mat-focused input:-webkit-autofill,
    #table_loginForm .mat-form-field.mat-focused input:-webkit-autofill:hover,
    #table_loginForm .mat-form-field.mat-focused input:-webkit-autofill:focus {
        -webkit-text-fill-color: #cccccc;
    }

    #tagLineContainer {
        display: none;
    }

    .mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
        background: white !important;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    button:disabled {
        color: white !important;
        background: #cccccc !important;
    }

    .cdk-overlay-backdrop.sidenav-menu {
        opacity: 1;
        background: rgba(0, 0, 0, 0.6);
    }

    .cdk-overlay-pane {
        position: absolute !important;
    }

    .cdk-overlay-connected-position-bounding-box,
    .cdk-overlay-pane {
        left: 0 !important;
    }

    .mat-mdc-menu-panel {
        min-width: 220px !important;
        max-width: 375px !important;
        border-radius: 0 !important;
        max-height: 100vh !important;
        height: 100vh !important;
        background-image: url(/assets/menu_gauche/colonne_background.png);
        background-repeat: repeat-x;
    }

    .mat-mdc-menu-panel button {
        color: white;
        padding: 10px;
        height: 67px;
    }

    .mat-menu-panel button img {
        margin: 0 auto;
    }

    .mat-mdc-menu-item {
        color: #fff !important;
        padding: 10px !important;
        height: 67px !important;
    }



    button.bottom-btn {
        background-color: rgb(49, 49, 49);
        border-top: 1px solid rgb(89, 89, 89);
    }

    .questionnaire-btn {
        background-image: url(assets/header/send/ico_send_selected.png);
        background-color: white !important; // #eae5e5 for gray
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center 58%;
        position: absolute !important;
        bottom: 18px;
        right: 18px;
    }

    .newItem-btn {
        background-color: white !important;
        position: absolute !important;
        bottom: 18px;
        right: 18px;
    }

    .newItem-btn i {
        font-size: 36px;
        line-height: 0.5;
    }

    .mat-elevation-z8 {
        box-shadow: none !important;
    }

    .landscape-menu-btn {
        background-color: white !important;
        position: absolute !important;
        z-index: 1000;
        top: 8px;
        right: 25px;
    }

    .landscapeCenter {
        margin: 0 auto;
        width: 50%;
    }

    .mat-form-field-label-wrapper .mat-form-field-label,
    .mat-form-field.mat-focused .mat-form-field-label,
    .mat-select-value-text {
        font-size: 14px;
    }

    .sticky {
        position: fixed;
        width: 100%;
        z-index: 1000;
        background-color: #ffffff;
    }

    .sticky0 {
        position: sticky;
        top: 5px;
        width: 100%;
        z-index: 1000;
        background-color: #ffffff;
    }

    .sticky .mat-grid-tile .mat-figure {
        justify-content: start;
    }

    .sticky .mat-grid-tile:first-child .mat-figure {
        padding-left: 17px;
    }

    .justifyCenter .mat-grid-tile .mat-figure,
    .sticky.justifyCenter .mat-grid-tile .mat-figure {
        justify-content: center;
        padding: 0;
    }

    .justifyLeft .mat-grid-tile .mat-figure {
        justify-content: left;
    }

    .justifyRight .mat-grid-tile .mat-figure {
        justify-content: flex-end;
    }

    .alignBottom .mat-grid-tile .mat-figure {
        align-items: flex-end;
    }

    .fullView-wrapper {
        padding: 85px 17px 10px 17px;
        position: relative;
        top: 112px;
        height: 100vh;
        overflow-y: scroll !important;
    }

    .fullView-wrapper-scrollfix {
        padding: 0 17px 160px 17px;
        position: relative;
        top: 112px;
        height: 100vh;
        overflow-y: scroll !important;
    }

    .edit-wrapper {
        top: 60px !important;
    }

    @media screen and (orientation: landscape) {
        .fullView-wrapper:not(.withPortraitButtons) {
            top: 5px;
        }

        .fullView-wrapper.withPortraitButtons {
            top: 80px;
        }
    }

    .fullView-wrapper .formWrapper {
        padding: 0;
    }

    .fullView-header {
        background: #eeeeee;
        .mat-grid-tile-content {
            justify-content: flex-start!important;
            h2 {
                padding-left: 17px;
            }
        }
    }

    .fullView-btn {
        padding: 0 10px !important;
        margin: auto !important;
        margin-top: 0 !important;
        background: #ffffff!important;
    }

    .fullView-icon {
        font-size: 16px !important;
    }

    .informationPanel {
        padding-bottom: 10px;
    }

    .informationPanel h2 {
        font-size: 1.3rem;
        font-weight: 600;
    }

    .informationPanel hr {
        margin: 10px 0;
    }

    .infoTable {
        font-family: Roboto, 'Helvetica Neue', sans-serif;
        font-size: 1.2rem;
        color: rgba(0, 0, 0, 0.57);
        width: 100%;
    }

    // .mobileReport {
    //     margin-bottom: 112px;
    // }

    .mobileFooter {
        background: #eeeeee;
        padding-bottom: 7px;
        padding-top: 7px;
    }

    .fieldsSection {
        padding-top: 20px;
    }

    .jobContentWrapper {
        // padding: 15px;
    }

    .jobContentWrapper .name {
        padding-bottom: 10px !important;
    }

    .jobContentWrapper .navbar-fixed-top {
        // top: 15px; // @ilya 2020-10-02 - canceled
        // left: 15px; // @ilya 2020-10-02 - canceled
    }

    .talentContainer td:not(.boldTitle) {
        font-size: 1.1rem;
    }

    .talentContainer td.boldTitle {
        font-size: 1.2rem;
    }

    .mobileParticipantSection h4 {
        margin-top: 5px;
    }

    .mobileParticipantSection .mat-form-field,
    .fullWidth {
        width: 100%;
    }

    .mat-form-field .mat-form-field-label.text-danger {
        color: indianred !important;
    }

    .sendContentTitle {
        width: 20%;
        font-weight: bold;
    }

    .sendContent table,
    .sendContent textarea,
    .sendContent input {
        width: 100% !important;
    }

    .mat-grid-tile.candidateName .mat-figure {
        justify-content: flex-start;
    }

    .mat-grid-tile.candidateDate .mat-figure {
        justify-content: flex-end;
    }

    #analysisListing td {
        display: block;
        padding-top: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
    }

    #analysisListing td:first-child {
        padding-top: 10px !important;
    }

    #analysisListing td h2 {
        font-family: Montserrat, sans-serif;
        font-size: 1.3rem;
    }

    #analysisListing td h3 {
        font-family: Montserrat, sans-serif;
        font-size: 1.2rem;
    }

    .analysisListDiv {
        margin-top: 10px !important;
    }

    .reportMiniGraphTitle {
        max-width: 75%;
    }
}

.analysisPersonName {
    width: 48%;
    vertical-align: middle;

    @media (max-width: $mobile) {
        width: auto;
    }
}

@media screen and (max-device-width: 568px) and (orientation: landscape) {
    #table_loginForm.formTable {
        margin-top: 0;
    }
}

@media screen and (min-device-width: 710px) {

    html,
    body {
        min-width: 710px !important;
    }
}

app-main-list {
    overflow: hidden;
}

.hide {
    display: none;
}

.na {
    color: #9a9a9a;
}

.subTitle {
    font-family: 'MontserratRegular', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4em;
    padding: 0 0 0.35em 0;
    margin-top: -11px;
    background-color: rgb(241, 241, 241);
}

.raJobProfile {
    padding-bottom: 10px;
}

@media (max-width: $desktop-xl) {
    .mat-column-creationDate {
        //width: 90px;
    }

    .mat-column-status,
    .mat-column-statusIac {
        //width: 110px;
    }

    .empty {
        padding-left: 10px;
        width: 167px;
    }

    .raJobProfile {
        display: none;
    }
}

@media #{ $desktop } {
    .raJobProfile {
        display: none;
    }
}

@media #{ $tablet, $tablet-landscape } {
    .sidebarJobInfo {
        display: none;
    }
}

.forceBreaks {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-wrap: $word-wrap-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.modal {
    z-index: 1000;
}

.dropdown-menu li a {
    text-decoration: none;
}

.text-danger {
    color: indianred;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                           not supported by any browser */
}

.fixedScroll {
    overflow: hidden;
}

/** Reset styles *******************************************************/

textarea,
input {
    outline: none;
}

/* this resets user agent "glow" around
input boxes */

table,
td,
tr,
th {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
}

a,
a:link,
a:visited,
a:hover,
a:focus,
a:active {
    background-color: transparent;
    color: #6a6a6a;
    text-decoration: underline;
}

a:hover,
a:focus {
    color: #000;
    text-decoration: none;
}

a.tdnone {
    text-decoration: none;
}

.tdnone.text-lowercase::first-letter {
    text-transform: uppercase;
}

img {
    border: none;
    display: block;
}

iframe {
    border: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1.3em;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
    padding: 0 0 1.3em;
}

input,
select,
option,
textarea {}

button {
    border: none;
    /** for IE **/
    margin: 0;
    padding: 0;
}

button::-moz-focus-inner

/** for firefox **/
    {
    border: none;
    padding: 0;
}

form {
    margin: 0;
    padding: 0;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

img

/** resizable images **/
    {
    max-width: 100%;
    height: auto;
    width: auto \9;
    /* ie8 */
}

iframe

/** resizable iframe **/
    {
    max-width: 100%;
    width: auto \9;
    /* ie8 */
}

strong {
    font-weight: normal;
}

hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 0.5px solid rgb(221, 221, 221);
}

/** Correct `block` display not defined in IE 8/9. **/

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
    display: block;
}

/** Clearfix **/

.clearfix,
.column_row,
.form_line,
.section_content {
    clear: both;
    display: inline-block;
}

.clearfix:after,
.column_row:after,
.form_line:after,
.section_content:after {
    content: ' ';
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}

/* Holly Hack Targets IE Win only \*/
* html .clearfix,
* html .column_row,
* html .form_line,
* html .section_content {
    height: 1%;
}

.clearfix,
.column_row,
.form_line,
.section_content {
    display: block;
}

.main_column {
    padding: 0;
}

/*** font definitions ***********************************/

@font-face {
    font-family: 'MontserratLight';
    src: url('/assets/fonts/montserrat-light-webfont.eot');
    src: url('/assets/fonts/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/montserrat-light-webfont.woff') format('woff'),
        url('/assets/fonts/montserrat-light-webfont.ttf') format('truetype'), url('/assets/fonts/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratRegular';
    src: url('/assets/fonts/montserrat-regular-webfont.eot');
    src: url('/assets/fonts/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/montserrat-regular-webfont.woff') format('woff'), url('/assets/fonts/montserrat-regular-webfont.ttf') format('truetype'),
        url('/assets/fonts/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratSemiBold';
    src: url('/assets/fonts/montserrat-semibold-webfont.eot');
    src: url('/assets/fonts/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/montserrat-semibold-webfont.woff') format('woff'), url('/assets/fonts/montserrat-semibold-webfont.ttf') format('truetype'),
        url('/assets/fonts/montserrat-semibold-webfont.svg#montserratsemi_bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MontserratBold';
    src: url('/assets/fonts/montserrat-bold-webfont.eot');
    src: url('/assets/fonts/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/montserrat-bold-webfont.woff') format('woff'),
        url('/assets/fonts/montserrat-bold-webfont.ttf') format('truetype'), url('/assets/fonts/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/** General styles *****************************************************/

.wrapper {
    max-width: 800px;
    display: table;
    margin: 1em auto 0 auto;
    padding: 0 1% 1.25% 1%;
    text-align: left;
    /* for a flexible width */
    width: 95.703125%;
    /** 980px / 1024px **/
}

h1 {
    font-family: 'MontserratLight', sans-serif;
    font-size: 28px;
    font-weight: normal;
    line-height: 1.4em;
    letter-spacing: 0.01em;
    padding: 0 0 0.5em 0;
}

h2 {
    font-family: 'MontserratLight', sans-serif;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.4em;
    padding: 0 0 0.35em 0;
}

h3 {
    font-family: 'MontserratRegular', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4em;
    padding: 0 0 0.35em 0;
}

h3.modal-title {
    font-size: 18px;
}

h4 {
    font-family: 'MontserratRegular', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4em;
    padding: 0 0 0.35em 0;
}

h4.innerTitleH4 {
    padding-bottom: 0;
    margin-bottom: 0;
}

.fontRegular {
    font-family: 'MontserratRegular', sans-serif;
}

.fontLight {
    font-family: 'MontserratLight', sans-serif;
}

.semiBold {
    font-family: MontserratSemiBold, sans-serif;
}

.bold,
.bold p {
    font-family: 'MontserratSemiBold', sans-serif;
}

.nav>li>a:focus,
.nav>li>a:hover {
    background-color: transparent;
    border: none;
}

.noResult {
    font-family: MontserratRegular, sans-serif;
}

.personCardName {
    padding-top: 15px;
    font-family: Montserrat, sans-serif;
    color: #444;
    width: 100%;
    font-size: 10pt;
    text-align: center;
    text-transform: uppercase;
}

.personCardSubTitle {
    color: #7e7e7e;
    padding-top: 8pt;
    font-size: 9pt;
    font-style: italic;
    text-align: center;
}

.creditTitle p {
    padding: 0;
    margin: 0;
}

.creditTitle span {
    font-family: MontserratSemiBold, sans-serif;
}

.talentContainer td.boldTitle {
    font-family: Montserrat, sans-serif;
}

.puckDisplay {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    width: 25%;
    float: left;
}

.puckDisplay.five {
    width: 20%;
}

/** separator **************************************************/

.sepa_bottom {
    border: none;
    border-bottom: solid 1px #dadada;
    height: 1px;
    margin: 0 0 1em 0;
}

/** couleurs ***************************************************/

.bg_white {
    background-color: #fff;
}

.bg_gray {
    background-color: #f1f1f1;
}

.bg_gray_dark {
    background-color: #7d7d7d;
}

.bg_gray_text {
    background-color: #6a6a6a;
}

.bg_gray_text2 {
    background-color: #7e7e7e;
}

.bg_accent {
    background-color: #ff671b;
}

.bg_error {
    background-color: #e45656;
}

.bg_positif {
    background-color: #68c2a5;
}

.bg_warning {
    background-color: #efc963;
}

/** boutons ****************************************************/

.button_pale {
    background-color: #fff;
    border: solid 1px #cfcfcf;
    color: #7e7e7e;
}

.button_dark {
    background-color: #7d7d7d;
    border: solid 1px #636363;
    color: #fff;
}

/*** main menu ***********************************************/

#menu_wrapper {
    position: fixed;
    height: 100vh;
}

#leftMenu {
    z-index: 1000;
    background-image: url('/assets/menu_gauche/colonne_background.png');
    height: 100%;
}

li.mainMenuButton a,
li.ngenioMenuButton a,
li.mainMenuButtonBottom a {
    padding: 0;
    margin: 0;
}

@media (max-height: 799px) and (min-height: 1px) {
    li.mainMenuButton a {
        width: 102px;
        padding: 5px 0;
        text-decoration: none;
    }
}

@media (min-height: 800px) {
    li.mainMenuButton a {
        width: 102px;
        padding: 15px 0;
        text-decoration: none;
    }
}

.padBorder0 {
    border: 0 !important;
    padding: 0 !important;
}

.sepBorderR {
    border-right: 1px #6a6a6a solid;
    padding-right: 10px;
}

.subSectionToggleButton {
    text-align: center;
    width: 20px;
    border: 1px #6a6a6a solid;
    cursor: pointer;
}

.withLink span {
    margin-left: 10px;
}

.withLink strong span {
    margin: 0;
}

.withLink span.pull-right:nth-child(2) {
    border-right: 1px #6a6a6a solid;
    padding-right: 10px;
}

.withLink a {
    font-weight: normal;
    font-size: 0.9em;
}

.falseInput .mat-form-field-wrapper,
.falseInput .mat-form-field-infix {
    padding: 0 !important;
    border: 0 !important;
}

.falseInput .mat-form-field-underline {
    display: none;
}

.mainMenuItemContainer img {
    margin: auto;
}

.mainMenuItemContainer div {
    font-family: 'MontserratLight', sans-serif;
    font-size: 10px;
    color: rgb(215, 215, 215);
    width: 100%;
    top: 29px;
}

.mainMenuButton {
    width: 102px;
}

img.leftMenuIcon {
    width: 75%;
    height: auto;
    margin: auto;
}

.mainMenuButton>a>div img:nth-child(2) {
    display: none;
}

.mainMenuButton:hover {
    background-color: rgb(49, 49, 49);
}

.mainButtonSelected a {
    background-color: rgb(89, 89, 89);
}

.mainButtonSelected a>div div.mainMenuItemText {
    color: rgb(233, 233, 233);
}

.mainButtonSelected a>div img:first-child {
    display: none;
}

.mainButtonSelected a>div img:nth-child(2) {
    display: inline-block;
}

.mainMenuButtonBottom {
    width: 102px;
    background-color: rgb(49, 49, 49);
    border-top: 1px solid rgb(89, 89, 89);
}

.mainMenuButtonBottomCorrector {
    padding-left: 5px;
}

.zeroPadding {
    padding: 0;
}

.mainMenuButtonBottom>a>div img:last-child {
    display: none;
}

.mainMenuButtonBottom:hover {
    background-color: rgb(43, 43, 43);
}

.mainButtonBottomSelected a {
    background-color: rgb(81, 81, 81);
}

.mainButtonBottomSelected a>div div.mainMenuItemText {
    color: rgb(233, 233, 233);
}

.mainButtonBottomSelected a>div img:first-child {
    display: none;
}

.mainButtonBottomSelected a>div img:last-child {
    display: inline-block;
}

.mainMenuItemContainer,
.mainMenuItemContainerLeft {
    position: relative;
    text-align: center;
    color: white;
}

.leftMenuIconAtRight {
    padding-top: 5px;
}

.mainMenuItemText,
.mainMenuItemTextLine2,
.mainMenuItemTextLeft {
    font-family: MontserratLight, sans-serif;
    font-size: 10px;
    color: rgb(215, 215, 215);
    width: 100%;
    position: absolute;
    height: 30px;
}

.mainMenuItemText {
    top: 35px;
}

.mainMenuItemTextLine2 {
    color: rgb(106, 106, 106);
    top: 60px;
}

.mainMenuItemContainerLeft {
    text-align: left;
}

.mainMenuItemTextLeft {
    padding-left: 18px;
    font-size: 11px;
    top: 12px;
}

.mainMenuBottomFiller {
    height: 100%;
}

/*** General layout ******************************************/
.unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.fakeLink {
    color: #558cca;
    text-decoration: underline;
    cursor: pointer;
}

.fakeLink:hover {
    text-decoration: none;
    cursor: pointer;
}

.noRightBorder {
    border-right: none;
}

#topMenuWrapper {
    padding: 28px 28px 10px 28px;
    background-color: white;
    z-index: 1;
    min-width: 610px;
    height: 160px;
}

.ngorange {
    color: rgb(227, 94, 36);
}

.rightWrapper {
    margin-left: 102px;
    /** space for the nav menu */
}

.adminListWrapper,
.peopleListWrapper {
    overflow-x: hidden;
}

.accntInfo table tr td label:not(.likeSpan) {
    font-family: Montserrat, sans-serif;
    margin-bottom: auto;
}

.accntInfo table tr td label input,
.accntInfo table tr td label select {
    font-family: MontserratLight, sans-serif;
}

my-date-picker .mydp .selection {
    font-family: 'MontserratLight', sans-serif !important;
}

.filtersPrefs {
    font-family: MontserratLight, sans-serif;
}

.adminFormTitle {
    font-family: 'MontserratLight', serif;
    font-size: 17pt;
}

.Margin14px {
    margin: 14px;
}

.LeftMargin0Margin14px {
    margin: 14px 14px 14px 0;
}

.padding0 {
    padding: 0 !important;
}

tr.actionModify {
    margin-bottom: 8px;
}

tr.actionModify td {
    border-bottom: 1px solid #eee;
    padding-bottom: 2px;
}

tr.actionModify td:nth-child(2) {
    padding-right: 15px;
}

tr.actionModify td:last-child {
    width: auto;
    vertical-align: middle;
}

tr.actionModify td:first-child {
    width: 150px;
}

tr.actionModify td:last-child div.editable {
    display: none;
}

tr.actionModify:hover td:last-child div.editable,
div.actionModify:hover div.editable {
    display: block;
    cursor: pointer;
}

tr.actionModify select:focus {
    outline: none;
}

.pencilBox {
    display: inline-block;
    float: right;
    height: 16px;
}

tr.actionModify:hover td:last-child div.editable div.editableIcon,
div.actionModify:hover div.editable div.editableIcon {
    height: 16px;
    width: 16px;
    background: url('/assets/administration/pencil-tool.png') no-repeat;
}

.editableIcon {
    height: 16px;
    width: 16px;
    background: url('/assets/administration/pencil-tool.png') no-repeat;
}

tr.actionModify:hover td:last-child div.editable div.editableIcon:hover,
div.actionModify:hover div.editable div.editableIcon:hover {
    background: url('/assets/administration/pencil-tool_on.png') no-repeat;
}

.tests15PercWidth {
    float: left;
    width: 15%;
}

.tests20PercWidth {
    float: left;
    width: 20%;
}

.tests25PercWidth {
    float: left;
    width: 25%;
}

.testsRightPad {
    padding-right: 15px;
}

.mb0 {
    margin-bottom: 0 !important;
}

.col17 {
    width: 17% !important;
}

.col14 {
    width: 14% !important;
}

.col83 {
    width: 83% !important;
}

.outerColumnGrey {
    overflow: hidden auto;
    padding: 0;
    margin: 0;
    height: 100vh;
}

.panelHr {
    border: 0;
    border-bottom: 1px solid #bcbcbc;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 10px;
}

.innerColumnGrey {
    /* should be affixed */
    padding: 30px 20px 20px 20px;
    margin: 0;
    background: #f1f1f1;
    height: 100vh;
}

.absolute {
    position: absolute;
}

.outerColumnWhite {
    background-color: white;
    padding: 1px 20px 20px 20px;
    height: 100vh;
    min-width: 623px;
}

.outerColumnWhite-sherpa {
    background-color: white;
    padding: 1px 20px 20px 20px;
    height: 100vh;
    min-width: 623px;
    margin-left: 400px;
}

.sherpa-outer-column {
    background: rgb(244,245,245);
    background: radial-gradient(circle at -16% -88%, rgb(244, 245, 245) 0%, rgb(254, 94, 44) 53%, rgb(41, 44, 50) 85%);
    min-height: 100vh;
    height: 100%;
    height: -webkit-fill-available;
    overflow: hidden auto;
    min-width: 623px;
    margin-left: 400px;
}

.topMenuCalcWidth {
    right: 0;
    left: 102px;
}

.greyBackground {
    position: fixed;
    top: 0;
    height: 100vh;
    background: rgb(241, 241, 241);
}

.tLeft {
    left: 102px;
    padding: 35px 20px 0 20px;
}

.tRight {
    padding: 35px 40px 0 0;
}

.gbrLeft {
    left: 102px;
}

.gbrRight {
    right: 0;
}

/**
 * Disable media queries related to
 * Bootstrap's responsiveness
 */

.container {
    width: 960px !important;
}

@media (min-width: 1px) {
    .container {
        max-width: 940px;
    }

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left;
    }

    .col-md-1 {
        width: 8.333333333333332%;
    }

    .col-md-2 {
        width: 16.666666666666664%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.33333333333333%;
    }

    .col-md-5 {
        width: 41.66666666666667%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.333333333333336%;
    }

    .col-md-8 {
        width: 66.66666666666666%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.33333333333334%;
    }

    .col-md-11 {
        width: 91.66666666666666%;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-push-1 {
        left: 8.333333333333332%;
    }

    .col-md-push-2 {
        left: 16.666666666666664%;
    }

    .col-md-push-3 {
        left: 25%;
    }

    .col-md-push-4 {
        left: 33.33333333333333%;
    }

    .col-md-push-5 {
        left: 41.66666666666667%;
    }

    .col-md-push-6 {
        left: 50%;
    }

    .col-md-push-7 {
        left: 58.333333333333336%;
    }

    .col-md-push-8 {
        left: 66.66666666666666%;
    }

    .col-md-push-9 {
        left: 75%;
    }

    .col-md-push-10 {
        left: 83.33333333333334%;
    }

    .col-md-push-11 {
        left: 91.66666666666666%;
    }

    .col-md-pull-1 {
        right: 8.333333333333332%;
    }

    .col-md-pull-2 {
        right: 16.666666666666664%;
    }

    .col-md-pull-3 {
        right: 25%;
    }

    .col-md-pull-4 {
        right: 33.33333333333333%;
    }

    .col-md-pull-5 {
        right: 41.66666666666667%;
    }

    .col-md-pull-6 {
        right: 50%;
    }

    .col-md-pull-7 {
        right: 58.333333333333336%;
    }

    .col-md-pull-8 {
        right: 66.66666666666666%;
    }

    .col-md-pull-9 {
        right: 75%;
    }

    .col-md-pull-10 {
        right: 83.33333333333334%;
    }

    .col-md-pull-11 {
        right: 91.66666666666666%;
    }

    .col-md-offset-1 {
        margin-left: 8.333333333333332%;
    }

    .col-md-offset-2 {
        margin-left: 16.666666666666664%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.33333333333333%;
    }

    .col-md-offset-5 {
        margin-left: 41.66666666666667%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.333333333333336%;
    }

    .col-md-offset-8 {
        margin-left: 66.66666666666666%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.33333333333334%;
    }

    .col-md-offset-11 {
        margin-left: 91.66666666666666%;
    }
}

/** Buttons **************************************************/

.modalButton,
.regularButton {
    background-color: #666;
    padding: 5px 10px;
    border: 1px solid #aaa;
    color: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.modalButton {
    background-color: #666!important;
    padding: 5px 10px!important;
    border: 1px solid #aaa!important;
    color: #fff!important;
    -moz-border-radius: 4px!important;
    -webkit-border-radius: 4px!important;
    border-radius: 4px!important;
}

.modalButton:hover,
.regularButton:hover {
    background-color: #aaa;
}

/**************************************************************/

* ::ng-deep * .modal {
    z-index: 10000000000000000 !important;
}

/*** central pane :: individus *******************************/

#topBar {
    padding: 0;
    margin: 0;
}

/* Title */

h1.centralPane {
    margin-top: 28px;
    font-size: 28px;
}

h1.sTitle {
    font-family: 'MontserratLight', sans-serif;
    font-weight: normal;
}

.mainTitle {
    float: left;
}

/* Search bar component */

.searchBarComponent {
    position: relative;
    float: right;
}

.searchBarContainer {
    /** can be deleted */
    float: right;
    margin-top: 32px;
    margin-bottom: 10px;
}

.searchResults {
    font-size: 13px;
    line-height: 14px;
    padding-top: 5px;
    display: block;
    float: right;
}

.searchResults span {
    font-weight: bold;
}

.clickMode {
    background-color: #dedede;
}

#typeMenu {
    position: relative;
    top: 13px;
}

#typeMenu div {
    padding: 0 3px;
}

#typeMenu div div {
    margin: auto;
    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.typeSelected {
    border-bottom: 5px solid rgb(227, 94, 36);
}

.extraPaddingRight {
    padding-right: 6px;
}

@media (max-width: $mobile) {
    .searchResults {
        text-align: right;
        padding: 0 6% 0 0;
    }
}

.resultArrow {
    font-size: 17px;
    display: inline-block;
    transform: rotate(330deg);
    -webkit-transform: rotate(330deg);
    -moz-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    -o-transform: rotate(180deg);
}

.pb0 {
    padding-bottom: 0;
}

.filterButton {
    float: right;
}

/*      >> Initial state */
div.filterButton>a>div img.filterIdle {
    display: block;
}

div.filterButton>a>div img.filterHover {
    display: none;
}

/*      >> Hovering */
.filterButton>a:hover div img.filterIdle {
    display: none;
}

.filterButton>a:hover div img.filterHover {
    display: block;
}

.searchBar {
    margin-top: 1px;
    width: 200px;
    height: 33px;
    border: 1px solid rgb(202, 202, 202);
}

.searchBar:focus {
    border: 1px solid rgb(126, 126, 126);
}

.magnifyingGlass,
.closeSearch {
    top: 6px;
    right: 5px;
    position: absolute;
}

/* Top menu */

ul.nav-sidebar li a:hover {
    /* overrides the default background that primes
                               over the ones defined below (!?) */
    background-color: transparent;
}

.nav-topbar {
    margin-bottom: 6px;
}

.arrowsNavWrap {
    position: absolute;
    bottom: 20px;
    right: 40px;
}

tr.topMenu td:last-child {
    border-right: 1px solid white;
}

tr.topMenu td:hover {
    border: 1px solid rgb(230, 230, 230);
}

tr.topMenu td {
    overflow: hidden;
    margin: 0;
    padding: 3px 10px;
    width: auto;
    height: 30px;
    border: 1px solid transparent;
    border-right: 1px solid rgb(230, 230, 230);
    cursor: pointer;
}

tr.topMenu td.selected {
    background-image: url('/assets/header/items_background.png');
    border: 1px solid rgb(230, 230, 230);
}

/*  .topMenuButton>a:focus > div div.topMenuItemText{color: rgb(233,233,233)};
    .topMenuButton>a:focus > div img:first-child{display:none};
    .topMenuButton>a:focus > div img:last-child{display:inline-block}; */

tr.topMenu td a {
    padding: 0;
    margin: 0;
}

tr.topMenu td img {
    float: left;
}

tr.topMenu td>a>div img:last-child {
    display: none;
}

tr.topMenu td a div {
    text-align: center;
    color: white;
}

tr.topMenu td a div div {
    margin-top: 2px;
    font-family: 'MontserratLight', sans-serif;
    float: left;
    font-size: 11px;
    color: rgb(106, 106, 106);
    margin-left: 5px;
}

.recordTypes {
    height: 100%;
    margin: 0;
    padding: 0;
}

.recordTypesButton {
    float: left;
    padding: 5px 5px 0 5px;
    height: 100%;
}

div.recordTypesButtonOn {
    border-bottom: 5px solid rgb(255, 103, 27);
}

div.recordTypesButtonOn>a {
    color: rgb(255, 103, 27);
}

.recordTypes a {
    font-family: 'MontserratLight', sans-serif;
    font-size: 11px;
    text-decoration: none;
    color: rgb(106, 106, 106);
}

.compare_corrector {
    position: relative;
    top: -2px;
}

#subMenu {
    margin-top: 0.88cm;
    margin-left: 0;
    margin-right: 0;
    border-bottom: 1px solid rgb(218, 218, 218);
}

#subMenu #subMenuLeft,
#subMenu #subMenuRight {
    height: 36px;
    margin: 0;
    padding: 0;
}

/* Listing */

.listHead th {
    font-family: MontserratSemiBold, sans-serif;
    color: white;
    font-size: 11px;
    background-color: rgb(113, 113, 113);
}

.individualColumnHeader {
    float: left;
    padding-right: 5px;
}

.orderingPair {
    float: left;
}

a.alert-info {
    color: white;
    background-color: rgb(113, 113, 113);
}

.orderingArrow {
    padding: 0;
    margin: 0;
    position: absolute;
    font-family: Courier New, Courier, monospace;
    font-size: 9px;
    color: rgb(177, 177, 177);
    text-decoration: none;
    cursor: pointer;
}

.orderingArrow:hover {
    color: rgb(218, 218, 218);
}

.orderingArrowDown {
    position: relative;
    top: 8px;
}

#individualRecords tbody tr td {
    font-size: 11px;
}

#infoPanelNews {
    height: 100vh;
}

#searchFilterColumn {
    height: 100vh;
    padding: 10px;
    /*padding-top: 35px;*/
    background-color: rgb(241, 241, 241);
}

#displayManagementTitle a img:last-child {
    display: none;
}

#displayManagementTitle a:hover img:first-child {
    display: none;
}

#displayManagementTitle a:hover img:last-child {
    display: inline-block;
}

#listingTypeAndReset {
    margin-top: 25px;
}

#listingTypeAndReset div a:first-child img:last-child {
    display: none;
}

#filtersOptions {
    margin-top: 25px;
}

.enddp my-date-picker .mydp .selector {
    margin-left: inherit !important;
    right: -1px;
}

.enddp my-date-picker .mydp .selectorarrowleft:after,
.enddp my-date-picker .mydp .selectorarrowleft:before {
    left: auto !important;
    right: 24px;
}

.date-input {
    width: 200px;
}

.plainList {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}

.modal-backdrop {
    z-index: 1000;
}

.whiteBck {
    background-color: #ffffff !important;
}

.headerText {
    padding-bottom: 10px;
}

#traitSummary h4,
#predictableBehavior h4,
#prb h4 {
    color: rgb(227, 94, 36);
    font-weight: normal;
}

#portrait h4 {
    font-size: 11px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
}

.reportMiniGraphHeader {
    margin-top: 15px;
    padding: 5px 3px 0 3px;
    width: 100%;
    margin-bottom: 15px;
    background: rgb(241, 241, 241);
}

.reportMiniGraphTitle {
    margin-top: 1px;
    margin-left: 10px;
    float: left;
}

.traitsTitle {
    margin-bottom: 15px;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.infoPanel {
    background-color: rgb(241, 241, 241);
    height: 100vh;
    padding: 10px 20px 10px 10px;
}

.infoPanelGraph {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
    border: 4px solid rgb(221, 221, 221);
    text-align: center;
}

.graphClass {
    margin: auto;
    width: inherit;
}

.headType {
    margin-right: 5px;
    padding: 3px;
}

.headTypeSelected {
    background-color: rgb(126, 126, 126);
}

.headTypeSelected a {
    color: white;
    text-decoration: none;
}

.clear {
    margin: 0;
    padding: 0;
}

#displayOutlineTitle h2 a img:last-child {
    display: none;
}

#displayOutlineTitle h2 a:hover img:first-child {
    display: none;
}

#displayOutlineTitle h2 a:hover img:last-child {
    display: inline-block;
}

#displayInformation h2 a img:last-child {
    display: none;
}

#displayInformation h2 a:hover img:first-child {
    display: none;
}

#displayInformation h2 a:hover img:last-child {
    display: inline-block;
}

/* Dragula */

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
    filter: alpha(opacity=80);
}

.gu-hide {
    display: none !important;
}

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.gu-transit {
    opacity: 0.2;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
    filter: alpha(opacity=20);
}

/* forms */

.form-control:focus {
    border-color: inherit;
    box-shadow: none;
    -webkit-box-shadow: none;
}

/*

.mainMenuButtonBottom>a>div img:last-child{display:none}
.mainMenuButtonBottom:hover {background-color: rgb(43,43,43)}

.mainButtonBottomSelected a {background-color: rgb(81,81,81)}
.mainButtonBottomSelected a > div div.mainMenuItemText{color: rgb(233,233,233)}
.mainButtonBottomSelected a > div img:first-child{display:none}
.mainButtonBottomSelected a > div img:last-child{display:inline-block}
*/

/* IDW report */

.DITInteractionTitleWithImage tr td img {
    height: 21px;
    width: 31px;
    vertical-align: middle;
}

.DITInteractionTitleWithImage tr td.DITInteractionTitleContainer {
    padding-top: 2px;
}

tr td.DITInteractionTitleContainer {
    padding-bottom: 7px;
}

label {
    font-weight: normal;
    margin-top: auto;
    margin-bottom: auto;
}

.candidateRow {
    padding-top: 5px;
    padding-bottom: 5px;
}

.DITInteractionTitleWithImage tr td.DITInteractionTitleContainer span {
    font-family: 'MontserratRegular', sans-serif;
    font-size: 11px;
    font-weight: normal;
    line-height: 1.4em;
    padding: 0;
    margin-bottom: 0;
    text-transform: uppercase;
}

/* Media Queries

@media (max-width: 1151px) {
    .topMenuItemText {
        display: none;
    }
}

@media (min-width: 569px) and (max-width: 1151px) {
    #subMenuLeft {
        width: 70%;
    }

    #subMenuRight {
        width: 30%;
    }
}
*/

.x-close {
    font-family: MontserratLight, sans-serif;
    font-size: 16pt;
    color: #7a7a7a;
    cursor: pointer;
}

.x-close:hover {
    color: #444;
}

#iacBox div,
#iacBox h2,
#iacBox .mb10 {
    display: block;
    margin-bottom: 10px;
}

.iacOutlineOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.65;
}

.iacOutlineTest {
    position: absolute;
    top: 46%;
    left: 0;
    width: 100%;
    font-size: 18pt;
    text-align: center;
    font-family: sans-serif;
    color: #666;
}

a {
    cursor: pointer;
}

.tooltip-question-mark {
    padding-left: 15px;
}

.modify {
    background-image: url('assets/buttons/edit.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 20px;
    cursor: pointer;
}

.modify:hover {
    background-image: url('assets/buttons/edit_hover.png');
    background-repeat: no-repeat;
}

textarea {
    resize: none;
}

.copy {
    margin-top: 1px;
    background-image: url('assets/buttons/copy.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 20px;
    cursor: pointer;
}

.copy:hover {
    margin-top: 1px;
    background-image: url('assets/buttons/copy_hover.png');
    background-repeat: no-repeat;
}

.duplicate {
    margin-top: 1px;
    background-image: url('assets/buttons/duplicate.png');
    background-repeat: no-repeat;
    height: 18px;
    width: 20px;
    cursor: pointer;
}

.duplicate:hover {
    margin-top: 1px;
    background-image: url('assets/buttons/duplicate_hover.png');
    background-repeat: no-repeat;
}

/**
    Login menu
 */

#table_loginForm {
    margin-top: 33px;
    width: 100%;

    @media (max-width: $mobile) {
        margin-top: 10px;
    }

    @media #{ $tablet } {
        width: 300px;
    }
}

#loginForm-l0 td,
#loginForm-l1 td {
    padding-bottom: 7px;

    @media (max-width: $mobile) {
        padding-bottom: 2px;
    }
}

#loginForm-0-2-0-cbox_container {
    width: 10px;
}

#loginForm-0-2-1-submit_button {
    width: 100%;
    right: 0;
    float: right;
}

#loginForm-0-0-0-middle_field div.formInnerDiv,
#loginForm-0-1-0-middle_field div.formInnerDiv {
    width: 100%;
}

#loginForm-0-0-0-tbox_container input[type='text'],
#loginForm-0-1-0-tbox_container input[type='password'] {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0;
    height: 23px;
    padding-left: 5px;
    color: #212121;
}

#loginForm-0-2-0-middle_field {
    padding-top: 6px;
}

#loginForm-0-2-0-right_text {
    padding-left: 10px;
    padding-top: 2px;
}

svg {
    font-family: sans-serif;
}

/**input[type="password"]
{
  font:small-caption;font-size:16px
}*/

/* Maintenance */

a.todo {
    color: black;
    background-color: yellow;
    font-weight: bold;
    text-decoration: underline;
}

div.graph-container {
    max-width: 400px;
}

#upButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.formats {
    font-size: 7pt;
    color: #888;
}

.name {
    font-family: MontserratLight, sans-serif;
    font-size: 13pt;
    margin-bottom: 0;
    padding-bottom: 0;

    @media (max-width: $mobile) {
        font-size: 1.4rem;
    }
}

.ng-sidebar {
    z-index: 1000 !important;
    background-color: #f1f1f1;
}

.ng-sidebar--closed {
    display: none;
}

.ng-sidebar__content {
    /**
    overflow: hidden !important;
    */
}

#upButton img {
    opacity: 0.4;
}

#upButton img:hover {
    opacity: 1;
}

input[type='checkbox'] {
    height: 17px;
    width: 13px;
    padding: 0;
    margin: 0;
    vertical-align: bottom;
    position: relative;
}

input[type='checkbox'][disabled] {
    cursor: default;
    filter: invert(20%);
}

/** MODAL / DIALOG **/
.modal-survey-request,
.modal-job-creation {
    min-width: 680px;
    max-width: 1100px;
    width: auto;
    margin: 30px auto;
}

.competences-modal {
    width: 1100px;
}

@media #{ $tablet } {
    .competences-modal {
        width: 99%;
    }

    .competences-modal .modal-content {
        max-height: 80vh;
        overflow-y: auto;
    }

    #tagLineContainer {
        display: block;
        text-align: center;
        margin-right: 0 !important;
        margin-top: 43px !important;
    }

    #tagTop,
    .tagEndBottom {
        padding-left: 0 !important;
    }

    app-form-table #table_loginForm {
        max-width: 100% !important;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: #000000 !important;
    }
}

.send-modal {
    width: 60%;
}

.ditWarning {
    width: 180px;
    margin: 14px;
}

#userContent label {
    display: block;
}

#userContent label input {
    width: 100%;
}

.profiler-modal {
    width: 500px;
}

.main-page-content {}

.main-page-content .sideBarButton {
    margin-right: 5px;
    margin-bottom: 5px;
    height: 32px;
    width: auto;
    padding: 5px;
    background-color: white;
    border: 1px solid #a5a5a5;
    cursor: pointer;
}

div#sideBarTestOptions {
    margin: 0 auto 10px auto;
}

div#sideBarTestOptions:last-child {
    margin-right: 0;
}

.testOption {
    padding: 2px 5px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 93%;
    font-family: MontserratRegular, sans-serif;
}

.testOption:not(.testOptionSelected):hover {
    color: #aaa;
    border-bottom: 2px solid rgb(230, 159, 90);
}

.testOptionSelected,
.testOptionSelected:hover {
    text-decoration: none;
    border-bottom: 2px solid rgb(227, 94, 36);
}

.testUnActivated {
    font-family: MontserratLight, sans-serif;
    text-decoration: none;
    color: #a7a7a7;
}

.testUnActivatedArchive {
    text-decoration: none;
    color: #a7a7a7;
}

.testUnActivatedArchive:hover {
    cursor: default;
    text-decoration: none;
    color: #a7a7a7;
    background-color: #eee;
}

.type {
    margin: 0 0 15px 0;
    padding: 0;
    font-weight: normal;
    font-style: italic;
    font-size: 9pt;
}

div.MPOSideBarPresentation h2 {
    margin-bottom: 0;
    padding-bottom: 0;
}

div.MPOSideBarPresentation h2#sideBarPortrait,
h2#sideBarPortrait {
    font-family: 'MontserratSemiBold', sans-serif;
    font-weight: bold;
    margin-bottom: 12.5px;
}

.notCompletedMark {
    position: absolute;
    border-radius: 9px;
    background: rgb(240, 123, 126);
    padding: 0;
    width: 9px;
    height: 9px;
    top: 0;
    right: -10px;
}

.information-sidebar {
    color: #555555 !important;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
}

.people-assistant-modal {
    // position: fixed;
    // left: 50%;
    // transform: translateX(-50%);
    // max-width: none !important;

    .modal-content {
        width: 600px;
        // height: 75vh;
        // background-color: #eeeeee;
    }
}

.thumbs-down-tooltip {
    .tooltip-inner {
        // width: fit-content;
        max-width: 350px;
        white-space: pre-wrap;
    }
}

// Needed to overide the breaking change in new ngx-bootstrap
.fade.modal {
    opacity: unset !important;
}

.modal-backdrop,
.modal-backdrop.show,
.bs-modal-backdrop,
.bs-modal-backdrop.show {
    opacity: 0.5;
}

.modal.fade .modal-dialog {
    transform: translateY(-100%);
    transition: visibility 0s linear 0.15s, opacity 0.15s linear, transform 0.2s ease-out;
}

.modal.show .modal-dialog {
    transform: translateY(0);
    transition: transform 0.2s ease-out;
}

@keyframes slideDown {
    0% {
        transform: translateY(-25%);
    }

    100% {
        transform: translateY(0);
    }
}

.modal.fade .modal-dialog {
    animation: 0.2s slideDown;
}

.mat-mdc-tab-header--mdc-tab-indicator-active-indicator-height {
    height: 0px !important;
}

.mdc-tab-indicator {
    height: 0px !important;
}

.mat-mdc-tab-label-container {

    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mdc-tab {
    color: #868686 !important;
    font-weight: bold !important;
    margin-left: 3px;

    &:hover {
        color: #555555 !important;
        border-bottom: 2px solid#e69f5a !important;
    }

    &.mdc-tab--active {
        color: #555555 !important;
        border-bottom: 2px solid rgb(227, 94, 36) !important;
    }
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: 0 !important;
}

.mdc-checkbox {
    display: flex !important;

    .mdc-checkbox__background {
        border-radius: 0;
        transition: background-color 90ms cubic-bezier(0, 0, 0.2, 0.1), opacity 90ms cubic-bezier(0, 0, 0.2, 0.1);
    }

    .mdc-checkbox__native-control:enabled:checked {
        ~.mdc-checkbox__background {
            background-color: $corp-orange !important;
            border-color: $corp-orange !important;
            color: white;
        }
    }

    .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        border-color: $disabled-checkbox !important;
        background-color: $disabled-checkbox !important;
    }

    .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
        border-color: $disabled-checkbox !important;
        background-color: $disabled-checkbox !important;
    }

    .mat-mdc-checkbox-touch-target {
        position: absolute;
        top: 50%;
        height: 18px;
        left: 50%;
        width: 18px;
        transform: translate(-50%, -50%);
    }

}

.mat-mdc-checkbox {
    padding-bottom: 3px;
}

.mdc-checkbox {
        padding: 0!important;
        .mdc-checkbox__background {
            top: 0!important;
            left: 0!important;
        }
    }


.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    background-color: #fff !important;
}



.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #adadad !important;
}

.mat-mdc-icon-button {
    color: #adadad !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    background-color: #adadad !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    color: #adadad !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    background-color: #adadad !important;
}

.datepicker-container {
    margin-bottom: 0 !important;
    .mat-mdc-form-field {
        display: flex !important;
    }
    p {
        padding: 0 !important;
    }
    .mat-mdc-form-field-subscript-wrapper {
        height: 15px!important;
    }
}

.datePicker {
    margin-bottom: 0 !important;
    .mat-mdc-form-field-flex {
        padding-top: 0 !important;
        padding-bottom: 0!important;
    }
}

.mat-mdc-checkbox .mdc-form-field {
    font-family: "MontserratLight", sans-serif;
    font-size: 12px!important;
    color: #6a6a6a !important;
    line-height: 1.5em;
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #adadad !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: #adadad !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.mat-mdc-form-field-infix {
    min-height: unset !important;
    max-height: 35px !important;
    width: unset !important;
    padding-top: 6px !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0 12px 0 5px !important;
    height: 35px !important;
    width: 35px !important;

}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0 12px 0 5px !important;
    height: 40px !important;
    width: 40px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 16px !important;
}

.mat-mdc-floating-label {
    color: #333333 !important;
    font-size: 14px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-22.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
    transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-icon-button svg {
    fill: #333333 !important;
}

.mdc-tab__text-label {
    letter-spacing: 0 !important;
}

.mat-mdc-tab .mdc-tab__text-label {
    color: #868686!important;
    font-family: Roboto, "Helvetica Neue", sans-serif!important;
    font-size: 14px!important;
    font-weight: 500!important;
}

.mdc-tab--active {
    .mdc-tab__text-label {
        color: #555555 !important;
        font-family: Roboto, "Helvetica Neue", sans-serif!important;
        font-size: 14px!important;
        font-weight: 500!important;
    }
}

.questionnaire-btn.mat-mdc-fab {
    z-index: 1;
}

.mat-mdc-menu-panel {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    min-width: 220px !important;
    max-width: 375px !important;
    border-radius: 0 !important;
    max-height: 100vh !important;
    height: 100vh !important;
    background-color: #3D3D3D !important;

    .mat-mdc-menu-item[disabled] {
        cursor: default;
        opacity: 1;
        background: #9e9a9a !important;
    }

    .mat-mdc-menu-item {
        padding: 10px;

        .mat-mdc-menu-item-text {
            height: 47px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 823px) {
    .sectionTitle {
        font-size: 2.3rem;
        padding-left: 10px;
    }
}

.cdk-overlay-connected-position-bounding-box {
    display: block !important;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
    color: #000 !important;
}


.mobile-form {
    .mat-mdc-form-field-infix {
        padding-bottom: 25px !important;
    }

    .mat-grid-tile-content {
        justify-content: flex-end !important;
    }

    button:first-child {
        margin-right: 10px;
    }

    .mdc-button {
        background-color: #666 !important;
        color: #fff !important;
        font-family: Roboto, "Helvetica Neue", sans-serif !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .mdc-checkbox {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .mdc-checkbox__background {
            top: 0 !important;
        }

        .mat-mdc-checkbox-ripple,
        .mdc-checkbox__ripple {
            display: none;
        }
    }
}

.mobile-search-field {
    .mat-mdc-form-field-infix {
        padding-bottom: 25px !important;
    }

    .mat-grid-tile-content {
        justify-content: flex-end !important;
    }

    button:first-child {
        margin-right: 10px;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        padding: unset !important;
        height: 35px !important;
        width: 35px !important;
        top: 5px;
    }

    .mdc-button {
        background-color: transparent !important;
        color: #6a6a6a !important;
        font-family: Roboto, "Helvetica Neue", sans-serif !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }


}

.jobs-mobile-form {
    .mat-grid-tile.start {
        .mat-grid-tile-content {
            justify-content: flex-start !important;

            .mdc-button {
                background-color: #666 !important;
                color: #fff;
                font-family: Roboto, "Helvetica Neue", sans-serif;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .mat-grid-tile.end {
        .mat-grid-tile-content {
            justify-content: flex-end !important;

            .mdc-button {
                background-color: #666 !important;
                color: #fff;
                font-family: Roboto, "Helvetica Neue", sans-serif;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .mat-mdc-form-field-infix {
        padding-bottom: 25px !important;
    }

    button:first-child {
        margin-right: 10px;
    }

    .mdc-checkbox {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .mdc-checkbox__background {
            top: 0 !important;
        }

        .mat-mdc-checkbox-ripple,
        .mdc-checkbox__ripple {
            display: none;
        }
    }

    .mat-grid-tile-content {
        justify-content: flex-end !important;

        .mdc-button {
            background-color: #666 !important;
            color: #fff;
            font-family: Roboto, "Helvetica Neue", sans-serif;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.mat-mdc-select-arrow,
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
    border-bottom-color: rgb(227, 94, 36) !important;
}


.mat-mdc-form-field-flex {
    padding-top: 10px;
    padding-bottom: 10px;
}

.mobile-header-container {
    .mat-mdc-form-field-flex {
        padding-bottom: 0px!important;
    }
}

.mobile-login-form {
    .mat-mdc-text-field-wrapper,
    .mdc-text-field--filled {
        background-color: transparent !important;
        color: #fff !important;

        .mat-mdc-form-field-infix {
            background-color: transparent !important;
            color: #fff !important;

            .mat-mdc-input-element,
            #mat-input-0 {
                background-color: transparent !important;
                color: #fff !important;
            }
        }
    }

    .mdc-checkbox__background {
        border-color: #fff !important;
    }

    .mat-mdc-checkbox .mdc-form-field {
        color: #fff !important;
    }
}

.report-choice-checkboxes {
    padding-top: 10px!important;
    .mdc-label {
        margin-top: 0px !important;
    }
}

.mdc-checkbox .mdc-checkbox__background {
    background-color: #fff !important;
}

.mat-column-distributor,
.mat-column-name {
    max-width: 220px!important;
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.mat-column-fullName {
    max-width: 100px!important;
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.mat-column-creationDate {
    max-width: 70px!important;
    div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.mat-column-status,
.mat-column-statusPrb,
.mat-column-statusIac {
    max-width: 40px!important;
}

.autoGenWrap {
    .mdc-label {
        margin-top: 0!important;
    }
}

typeahead-container.dropdown-menu {
    display: flex!important;
    flex-direction: column!important;
    width: 100%!important;
    padding: 0 0!important;

    button.dropdown-item {
        text-align: left!important;
        padding: 5px 5px 5px 10px;
        border-bottom: 1px solid rgb(218, 218, 218);
        background-color: #fff;
        &:hover {
            background-color: #EEE;
        }
    }
  }


.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0.00!important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0.00!important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow,
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after
{
    color: #e35e1b !important;
}

.person-adding-component {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: #ffffff!important;
    }

    .mdc-text-field {
        padding: 0!important;
        margin-top: -30px!important;
    }
}

.mobile-idw-matrix {
    margin-bottom: 150px;
}

.btn-clear-date {
    background-color: transparent!important;
}

// .sherpa-container {
//     .bs-tooltip-top {
//         display: none!important;
//       }
// }


.perception-tooltip,
.talents-tooltip,
.iac-tooltip {
    background-color: #4D5056!important;
    box-shadow: 0px 4px 13.1px 0px rgba(0, 0, 0, 0.25)!important;
    .tooltip-arrow {
        border-color: #4D5056!important;
    }
    .tooltip-inner {
        background-color: #4D5056!important;
        max-width: 180px!important;
        font-family: Arial;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        white-space: pre-wrap;
        text-align: left!important;
    }
    
}
.perception-tooltip {
    top: 60px!important;
    left: 0!important;
    text-align: left!important;
    transform: translate3d(224px, -70px, 20px)!important;
    box-shadow: 0px 4px 13.1px 0px rgba(0, 0, 0, 0.25)!important;
    .tooltip-inner {
        min-width: 333px!important;
        text-align: left!important;
    }
}